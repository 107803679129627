import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AsyncComponent } from '@twigeducation/async-component';
import { withSignoutRedirect, RedirectToLogin } from '@twigeducation/oidc-client-react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button } from '@twigeducation/ts-fe-components';
import { RightThin } from '@twigeducation/ts-fe-components/components/Icons';
import onLogout from '../../helpers/onLogout';
import { Translation } from '../Translation';
import { startWelcomeTour } from '../../store/actions/teacherOnboarding/showWelcomeTour';
import AsyncComponentError from '../Error/AsyncComponentError';

const LoginButton = () => {
    const [redirect, setRedirect] = useState(false);
    return (
        <>
            <Button
                data-at="header-secondary-nav-item"
                iconRight
                key="log-in"
                primary
                onClick={() => setRedirect(true)}
            >
                <Translation translationKey="general.login" defaultValue="Log in" />
                <RightThin size={15} />
            </Button>
            {redirect && <RedirectToLogin />}
        </>
    );
};

const Header = ({ territory, disableHeader, ...props }) => {
    const dispatch = useDispatch();
    const primaryLanguage = useSelector(store => store.i18n.primaryLanguage);

    return (
        !disableHeader && (
            <AsyncComponent
                {...props}
                assessmentsPlatformUrl={window.config.ASSESSMENTS_PLATFORM_URL}
                authenticationUrl={window.config.AUTHENTICATION_URL}
                twigScienceToolsUrl={window.config.TWIG_SCIENCE_TOOLS_URL}
                twigScienceReporterUrl={window.config.TWIG_SCIENCE_REPORTER_URL}
                teacherOnboardingFunc={primaryLanguage === 'en-US' ? () => dispatch(startWelcomeTour()) : null}
                appName="ts-header"
                componentName="Header"
                errorComponent={AsyncComponentError}
                territory={territory}
                product="twigscienceK-6"
                loginComponent={LoginButton}
            />
        )
    );
};

Header.defaultProps = {
    territory: 'unknown-territory',
    disableHeader: false,
};

Header.propTypes = {
    territory: PropTypes.string,
    disableHeader: PropTypes.bool,
};

export default withSignoutRedirect(onLogout)(
    connect(state => ({
        territory: state?.subscriptions?.territory?.name,
        disableHeader: state?.isLti,
    }))(Header),
);
